/* eslint-disable no-undef */
'use strict';

function pushCustomer(login) {
    if (login) {
        _etmc.push(['setOrgId', '514001643']);
        _etmc.push(['setUserInfo', { email: login }]);
        _etmc.push(['trackPageView']);
    }
}

function cleanCart() {
    _etmc.push(['setOrgId', '514001643']);
    _etmc.push(['trackCart', { clear_cart: true }]);
}

function trackConversion(element) {
    var products = [];
    if (element) {
        $(element).find('.product-line-item').each(function () {
            var product = {};

            if ($(this).find('.item__carrossel').length > 0) {
                product.item = $(this).find('.product-line-item').data('ticket-number').toString();
                product.unique_id = $(this).find('.product-line-item').data('ticket-number').toString();
            }

            if ($(this).find('.order__infos--price').length > 0) {
                product.quantity = $(this).find('.order__infos--price').innerText;
                product.price = $(this).find('.order__infos--price').innerText;
            }

            products.push(product);
        });

        _etmc.push(['setOrgId', '514001643']);
        _etmc.push(['trackConversion', { cart: products }]);
    }
}

function trackCart() {
    var products = [];
    // var price = $('.itinerary-price').first().text().length > 0 ? $('.itinerary-price').first().text() : seatPriceInfo;

    $('.inner-seat.selected').each(function () {
        var product = {
            item: $('.ticket__content--body').find('.ticket__trip').first().text()
                .trim() + ' - ' + $('.ticket__content--body').find('.ticket__trip').eq(1).text()
                .trim() + ' - ' + $(this).find('.seat-number').text(),
            quantity: '1',
            price: $('.itinerary-price').first().text().length > 0 ? $('.itinerary-price').first().text() : $('#seatPriceInfo').text(),
            unique_id: $('.ticket__content--body').find('.ticket__trip').first().text()
                .trim() + ' - ' + $('.ticket__content--body').find('.ticket__trip').eq(1).text()
                .trim() + ' - ' + $(this).find('.seat-number').text()
        };
        products.push(product);
    });

    _etmc.push(['setOrgId', '514001643']);
    _etmc.push(['trackCart', { cart: products }]);
}

module.exports = {

    methods: {
        pushCustomer: pushCustomer,
        cleanCart: cleanCart,
        trackConversion: trackConversion,
        trackCart: trackCart
    },

    checkout: function () {
        $('.order__card').ready(function () {
            if ($('.order__card').length > 0) {
                var parent = $('.product-summary-block');

                trackConversion(parent);
            }
        });
    },

    search: function () {
        $('.home-searchTicket__button').find('.search-tickets-btn').on('click', function () {
            var search = $(this).parent().siblings().find('.cities__origin')
                .val() + ' - ' + $(this).parent().siblings().find('.cities__destination')
                .val() + ' - ' + $('#inputDeparture').val();

                const inputBackValue = $('#inputBack').val();
                if (inputBackValue && inputBackValue.length > 0) {
                    search += ' - ' + inputBackValue;
                }

            _etmc.push(['setOrgId', '514001643']);
            _etmc.push(['trackPageView', { search: search }]);
        });
    },

    service: function () {
        $('.ticket__content').find('.ticket__button').on('click', function () {
            _etmc.push(['setOrgId', '514001643']);
            _etmc.push(['trackPageView', { category: $(this).siblings('#service-id').val() }]);
        });
    }
};
